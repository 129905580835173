.<template>
  <div class="panel-full panel guestfull" v-show="isActive">
    <div>
      <div class="guest-lookup">
        
        <button class="btn-small actionbutton" @click="selectDefaultGuest" v-if="defaultGuestId>0">Start Guest Ticket</button>
        <input ref="lookupguest" type="search" :placeholder="guestlookuptext" class="textbox" v-model="lookupString" @keyup.enter="lookupGuest()" v-if="showLookupGuest"/>
        <button class="btn-small actionbutton" @click="lookupGuest" v-if="showLookupGuest">Lookup</button>
        
        <addguest :guestId="-1" @refresh="refresh" :isNew="paramTrue" class="newbutton" v-if="showAddGuest"/>
      </div>
    </div>

    <div  class="scrollbox">
      <div v-if="pleaseWait">
        <label class="pleasewait" for>Please Wait...</label>
      </div>
      <div :class="guestclass(g)" v-for="g in guestList" :key="g.id" :value="g.id" @click="selectGuest(g)">
        <div class="horizFlexStretch">
          <label class="text subtitle" for>{{g.name}}</label>
          <label class="text" for>{{guest.PhoneNumber}}</label>
          <updateguest :guestId="g.id" @refresh="refresh" v-show="showUpdate(g)" class="editbutton"/>
        </div>
      </div>
      
      <div >
        <label class="text subtitle" for>{{responseMessage}}</label>
      </div>
    </div>
    

    <div class="nextbutton">
      <button class="btn" @click="toNext" v-show="isValid">Continue to Cart</button>
    </div>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import format from '../../style/format.js'
import dataModel from '../../store/dataModel'
import datamethods from '../../services/datamethods.js'
import updateguest from '../../components/actions/updateguest.vue'
import addguest from '../../components/actions/addguest.vue'
export default {
  components:{
    updateguest,
    addguest
  },
  data() {
    return {
      guestlookuptext: "Lookup Guest",
      lookupString:'',
      paramTrue:true,
      inSelectGuest:false,
    };
  },
  mixins:[dataModel, datamethods,format],
  props: ["panelIndex"],
  computed: {
    ...mapFields([
            'panels',
            'allowUpdateGuest',
            'guest',
            'guestText',
            'pleaseWait',
            'responseMessage',
            'availableCartItems',
            'availableCartItemTypes',
            'guestList',
            'location',
            'businessDayId',
            'terminalId',
            'ticket',
            'guestAccounts',
            'loggedInUser',
            'faBalance',
            'guestLocationId',
            'selectedCartItemType',
            'arPaymentItemSubtypes'
            
        ]),
        defaultGuestId(){
          
          if(window.DEFAULT_GUESTID==undefined){return 0}
          else{return window.DEFAULT_GUESTID}
        },
    showUpdateGuest: function() {
      return this.allowUpdateGuest && this.isValid;
    },
    showAddGuest(){
      
      if(window.SHOW_ADD_GUEST==undefined){return false}
      else{return window.SHOW_ADD_GUEST}
    },
    
    showLookupGuest(){
      
      if(window.SHOW_GUEST_LOOKUP==undefined){return true}
      else{return window.SHOW_GUEST_LOOKUP}
    },
    isActive: function() {
      return this.panels[this.panelIndex].active;
    },
    isValid: function() {
      return this.panels[this.panelIndex].valid;
    },
    priorValid: function() {
      return this.panels[this.panelIndex - 1].valid;
    },
    AutoSelectGuestIfOneResult(){
      if(window.AUTO_SELECT_GUEST_IF_ONE_RESULT==undefined){return false}
      else{return window.AUTO_SELECT_GUEST_IF_ONE_RESULT}
    },
    productsByGuest(){
      if(window.SELECT_PRODUCTS_BY_GUEST==undefined){return false}
      else{return window.SELECT_PRODUCTS_BY_GUEST}
    },
    showAR(){
      
      if(window.SHOW_AR==undefined){return false}
      else{return window.SHOW_AR}
    },
  },
  watch:{
    isActive:function(val){
      if(this.isActive){this.show()}
    }
  },
  methods: {
   show(){
    this.$nextTick(() => {
      this.$refs.lookupguest.focus();
    })
   },
   selectDefaultGuest(){
    var defaultguest = {id:this.defaultGuestId, name:'Guest'}
    this.selectGuest(defaultguest)
   },
    async lookupGuest(){
        this.$emit('checkforopen')
        let req = {
          lookupString:this.lookupString
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'lookupguest')
        //handle response
     
        if(response.Successful){
          //reset flag
          this.inSelectGuest=false
          
          this.guestList= response.GuestList
          if(this.guestList.length==0){this.responseMessage="No Guests Found"}
          
          //autoselect?
          if(this.AutoSelectGuestIfOneResult){
            if(this.guestList.length==1){
              this.selectGuest(this.guestList[0])
            }
          }
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      },
      guestclass(g){
        let c = "guestdetails panel-inner"
        if(this.isSelected(g)){
          c=c+" color-highlight"
        }else{c=c+" color-inner"}
        return c
      },
      showUpdate(g){
        return this.isSelected(g) && this.showUpdateGuest
      },
      async selectGuest(g){
        //if we are already selecting someone...dont' do another one
        if(this.inSelectGuest){return}

        this.inSelectGuest=true //set flag

        this.guest=g

        //this.AddTicket();
        //this.GetGuestAccount();

        //  SELECT
        this.panels[0].valid = true;
        this.panels[1].available = true;
        
        this.panels[0].title =  this.guest.name ;
        //this.guestText + ': ' +

        this.GetGuestAccount();
        this.GetGuestFinancialAid();
        if(this.productsByGuest){this.getProductsByGuest()}
        if(this.showAR){this.getARPaymentSubTypeByGuest()}
        //this.toNext()
      },
      refreshGuest(){
        this.GetGuestAccount(false);
        this.GetGuestFinancialAid();

        if(this.productsByGuest){this.getProductsByGuest()}
      },
      async getProductsByGuest(){
        if(window.USE_PRODUCT_GROUPS){
          this.getGroupsByGuest()
          return;
        }
        let req = {
                id:this.guest.id,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'producttypesbyguest')

            //handle response
            if(response.Successful){
              let tmpItems= response.productTypeList
              this.guestLocationId = response.locationId
              
              if (tmpItems == undefined) {
                let noneItem = dataModel.NoItemsItem();
                this.availableCartItemTypes.push(noneItem);
              } else {
                this.availableCartItemTypes = tmpItems;
              }
              if(this.availableCartItemTypes.length>0){
                this.selectedCartItemType=this.availableCartItemTypes[0].id
                this.availableCartItemTypes[0].selected=true
              }else{this.selectedCartItemType=0}
              //if(this.itemList.length==1){
                  //select the type to skip it
              
                  //this.select(this.itemList[0].guid)
                  
              //}
            }else{
              this.responseMessage = response.Message
            }
            this.pleaseWait=false;
      
      },
      async getARPaymentSubTypeByGuest(){
        //clear
        this.arPaymentItemSubtypes = []
       
        let req = {
            ID:this.guest.id,
        }  
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(req,'subtypebyguest')
          //handle response
      
          if(response.Successful){
            this.arPaymentItemSubtypes = response.ValueList
            //this.ARPaymentItemSubtypeId = response.LocationPOS.ticketPaymentItemSubtypeId_AR
            //this.ARPaymentItemSubtypeName = response.LocationPOS.ticketPaymentItemSubtypeName
          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
      },
      async getGroupsByGuest(){
        let req = {
            id:this.guest.id,
        }  
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(req,'productgroupbyguest')
          //handle response
      
          if(response.Successful){
            
            let tmpItems= response.ProductGroupList
            
            if (tmpItems == undefined) {
              let noneItem = dataModel.NoItemsItem();
              this.availableCartItemTypes.push(noneItem);
            } else {
              this.availableCartItemTypes = tmpItems;
            }
            if(this.availableCartItemTypes.length>0){
              this.selectedCartItemType=this.availableCartItemTypes[0].id
              this.availableCartItemTypes[0].selected=true
            }else{this.selectedCartItemType=0}
          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
      
      },
      async AddTicket(){
        let req = {
          ticket:{
            guestId:this.guest.id,
            businessDayId:this.businessDayId,
            locationId:this.location.id,
            terminalId:this.terminalId,
            employeeId:this.loggedInUser.user_personId
          }
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'addticket')
        //handle response
     
        if(response.Successful){
          this.inSelectGuest=false //turn off flag

          this.ticket.id=response.ResponseInteger
          this.toNext();//
          this.$store.dispatch('startOver_Cart');

          this.$nextTick(() => {
            this.$emit('refreshticket');
          })
          
          
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      },
      async GetGuestAccount(createticket=true){
        let req = {
          personId:this.guest.id,
          locationId:this.location.id
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'AccountByGuest')
        //handle response
     
        if(response.Successful){
          this.guestAccounts = response.AccountList
          this.guest.code=response.Guest.Code
          this.guest.messageString = response.Guest.messageString
          this.guest.alertString = response.Guest.alertString
          this.guest.isStudent=response.Guest.isStudent
          this.panels[0].title = this.panels[0].title 
          this.panels[0].subtitle1= this.guest.code 
          if(window.SHOW_FINANCIALAID==false || this.guest.messageString!=null){
            this.panels[0].subtitle2=  this.guest.messageString
          }
          
          this.panels[0].subtitle3=response.Guest.alertString
          console.log(response.Guest.loyaltyBalance)
          if(response.Guest.loyaltyBalance >0){
            this.panels[0].subtitle4=String(response.Guest.loyaltyBalance) + ' loyalty points'
          }else{
            this.panels[0].subtitle4=null
          }
          
          this.panels[0].image = response.Guest.photoFileName

          //NOW add the ticket
          if(createticket){this.AddTicket();}
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      },
      async GetGuestFinancialAid(){
        //only if we are showing fa
        if(window.SHOW_FINANCIALAID==false){return}

        this.panels[0].subtitle2="Looking up Financial Aid..."

        let req = {
          personId:this.guest.id,
          locationId:this.location.id
        }
        //call api
        this.$store.dispatch('beforeAPI');
        
        var response = await this.callAPI(req,'financialaidbyguest')
        //handle response
        
        if(response.Successful){
          this.faBalance=Number(response.StringResponse)
         
          if(this.faBalance<=0){
            this.panels[0].subtitle2="NO Financial Aid Available"
            this.faBalance=0
          }else{
            this.panels[0].subtitle2="Financial Aid: $" + this.faBalance.toFixed(2)
          }
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      },

      isSelected(g){
        return g.id==this.guest.id
      },
      refresh(lookupstring){
        console.log('lookupstring ' + lookupstring)
        if(lookupstring!=''){
          this.lookupString=lookupstring
        }
        this.lookupGuest()
      },
      
    toNext: function() {
      this.resetGuest();
      

      /* this.AddTicket();
      this.GetGuestAccount();*/
      var nextPanelIndex = Number(this.panelIndex) + 1;
      this.$store.dispatch('setActivePanel',nextPanelIndex); 
    },
    resetGuest(){
      this.guestList=[]
      this.lookupString=""
    }
  }
};
</script>

<style scoped>
.guestfull {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px auto 80px;
  grid-template-areas:
    "lookup"
    "details"
    "next";
}

.guest-lookup {
  grid-area: lookup;
  display: flex;
  flex-direction: row;
}
.guestdetails {
  grid-area: details;

  display: flex;
  flex-direction: column;
}

.updateguestbutton {
  margin: 20px 0px 0px 5px;
}
.scrollbox{
  height:450px;
  overflow-y: scroll;
  overflow-x:hidden;
}
.newbutton{
  margin:20px 20px 20px 20px;
}

.editbutton{
  margin:10px;
}
</style>