.<template>
  <div :class="PanelClass" @click="setActivePanel">
    <div class="vertFlex">
      <label for class="title">{{title}}</label>
      <label for class="subtitletab" v-if="subtitle1!=null">{{subtitle1}}</label>
     <label for class="subtitletab" v-if="subtitle2!=null">{{subtitle2}}</label>
     <label for class="subtitletabalert" v-if="subtitle3!=null">{{subtitle3}}</label>
     <label for class="subtitletab" v-if="subtitle4!=null">{{subtitle4}}</label>
      <div class="personPhoto" v-if="image!=null">
            <img :src="getImage"  width="150" class="imageclass" >
      </div>

    </div>
    <confirm :showButton="falseParam" ref="completecurrent" body="Do you want to complete the current transaction before starting a new transaction?" title="Complete" @yes="completetransaction" @no="goaheadandsetpanel" />
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import confirm from '../actions/confirm.vue'
export default {
  data() {
    return {
      falseParam:false
    };
  },
  props: ["panelIndex"],
  components:{confirm},
  computed: {
     ...mapFields([
            'panels',
            'imageBasePath',
            'payItems',
            'checksetindex',
            'isCompletable',
            'responseMessage'
        ]),
    getImage(){
       // console.log('getimage ' + this.imageBasePath + this.image)
       if(this.image==null){return null}
        return this.imageBasePath + this.image;
    },
    image: function() {
      return this.panels[this.panelIndex].image;
    },   
    title: function() {
      return this.panels[this.panelIndex].title;
    },
    subtitle1: function() {
      return this.panels[this.panelIndex].subtitle1;
    },
    subtitle2: function() {
      //console.log("sub2" + this.panelIndex + ' ' + this.panels[this.panelIndex].subtitle2)
      return this.panels[this.panelIndex].subtitle2;
    },
    subtitle3: function() {
      //console.log("sub3" + this.panelIndex + ' ' + this.panels[this.panelIndex].subtitle3)
      return this.panels[this.panelIndex].subtitle3;
    },
    subtitle4: function() {
      //console.log("sub3" + this.panelIndex + ' ' + this.panels[this.panelIndex].subtitle3)
      return this.panels[this.panelIndex].subtitle4;
    },
    PanelClass: function() {
      var classes = "panel " + this.panels[this.panelIndex].layoutClass;
      if (this.panels[this.panelIndex].available == false) {
        classes += " panel-unavailable";
      } else if (this.inPayment == true) {
        classes += " panel-unavailable";
      } else {
        classes += this.panels[this.panelIndex].valid
          ? " panel-valid"
          : " panel-invalid";
      }

      classes += this.panels[this.panelIndex].active
        ? " panel-active"
        : " panel-inactive";
      return classes;
    }
  },
  methods: {
    setActivePanel() {
      var oktoset = true
      console.log('checksetindex ' + this.checksetindex)
     if(this.checksetindex){
        //case of asking before we start over if there is a pmt
        console.log('setactive ' + this.panelIndex + ' ' + this.payItems.length)
          if(this.panelIndex==0 && this.payItems.length>0){
            //if we aren't completable then we need to set msg
            if(!this.isCompletable){
              this.responseMessage="Please complete the transaction"
              return
            }
            this.checksetindex=false
            oktoset=false
            this.$refs.completecurrent.Show()
            return
          }
     }
      
     if(oktoset){
       console.log('oktoset')
        this.$store.dispatch('setActivePanel',this.panelIndex);

        // let the caller know
        this.$emit('setpanel',this.panelIndex)
     }

      
      
    },
    
    goaheadandsetpanel(){
      
      this.setActivePanel(true)
    },
     completetransaction(){
       
        this.$emit('completetransaction')
     }   
  }
};
</script>

<style scoped>
.imageclass{
  margin:0px;
  width:140px;
}
</style>